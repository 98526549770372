import {Image} from "react-bootstrap";
import AppPage from "../AppPage/AppPage";
import React from "react";

function FamilyTreePage() {
    const familyTreeSvg = process.env.PUBLIC_URL + '/assets/LesCousins.svg';

    return (
        <AppPage id="family-tree-page" title="L'arbre familial">

            <a target="_blank" href={familyTreeSvg} rel="noreferrer">
                <Image src={familyTreeSvg} alt="L'arbre familial" fluid thumbnail></Image>
            </a>

        </AppPage>
    );
}

export default FamilyTreePage;
