import {FamilyEvent} from "../model/FamilyEvent";
import useApproximateDateFormatter from "./useApproximateDateFormatter";
import {useCallback} from "react";

function useFamilyEventFormatter(): (e?: FamilyEvent, prefix?: boolean) => string {
    const dateFormatter = useApproximateDateFormatter();

    return useCallback(
        (e, p) => {
            let formattedFamilyEvent = dateFormatter(e?.date, p);

            if (e?.place) {
                formattedFamilyEvent += formattedFamilyEvent ? ` à ${e.place}` : `à ${e.place}`;
            }

            return formattedFamilyEvent;
        },
        [dateFormatter]
    );
}

export default useFamilyEventFormatter;
