import React from "react";
import {Card, Col, Form, Placeholder, Row} from "react-bootstrap";

export default function IndividualSummaryPlaceholder() {
    return <Card>
        <Card.Body>

            <Form>

                {
                    [
                        {
                            controlId: 'formId',
                            label: 'Identifiant',
                        },
                        {
                            controlId: 'formName',
                            label: 'Nom complet',
                        },
                        {
                            controlId: 'formSurname',
                            label: 'Nom de famille',
                        },
                        {
                            controlId: 'formGiveName',
                            label: 'Prénom',
                        },
                        {
                            controlId: 'formBirthDate',
                            label: 'Naissance',
                        },
                        {
                            controlId: 'formGender',
                            label: 'Genre',
                        },
                        {
                            controlId: 'formOccupations',
                            label: 'Occupations',
                        },
                        {
                            controlId: 'formDeathDate',
                            label: 'Décès',
                        },
                    ].map(formGroup => <IndividualFormGroup {...formGroup} key={formGroup.controlId}/>)
                }
            </Form>
        </Card.Body>
    </Card>;
}

function IndividualFormGroup(props: { controlId: string; label: string; }) {

    return <Form.Group as={Row} className="mb-3" controlId={props.controlId}>
        <Form.Label column sm={3}>{props.label}</Form.Label>
        <Col sm={9}>
            <Placeholder as={'div'} animation="glow" >
                <Placeholder xs={6}/>
            </Placeholder>
        </Col>
    </Form.Group>
}


