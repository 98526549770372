import {byBirthDateComparator, Individual} from "../../../model/Individual";
import React, {useContext, useEffect, useState} from "react";
import {AppServiceContext} from "../../../providers/AppServiceProvider/AppServiceProvider";
import {Family} from "../../../model/Family";
import IndividualGallery from "../../../components/IndividualGallery/IndividualGallery";
import IndividualCard from "../../../components/IndividualCard/IndividualCard";
import IndividualCardPlaceholder from "../../../components/IndividualCard/IndividualCardPlaceholder";

export default function ChildrenSection({individual}: { individual?: Individual }) {
    const {individualService, familyService} = useContext(AppServiceContext);
    const [familySpouses, setFamilySpouses] = useState<Family[]>([]);
    const [childrenPerSpouses, setChildrenPerSpouses] = useState<{ spouse?: Individual, spouseChildren: Individual[] }[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (individual) {
            const familySpouses = individual.familySpouses
                .map(spouse => familyService.findOne(spouse))
                .filter(spouse => !!spouse) as Family[];
            setFamilySpouses(familySpouses);
        }
    }, [individual, familyService]);

    useEffect(() => {
        if (individual) {
            const childrenPerSpouses = familySpouses.map(familySpouse => {
                const spouse = [familySpouse.husband, familySpouse.wife]
                    .filter(spouse => spouse && spouse !== individual.id)
                    .map(spouse => individualService.findOne(spouse || ''))
                    .filter(spouse => !!spouse)
                    .pop();

                const spouseChildren = familySpouse.children
                    .map(child => individualService.findOne(child || ''))
                    .filter(child => !!child)
                    .sort(byBirthDateComparator) as Individual[];

                return {spouse, spouseChildren};
            }).filter(childrenPerSpouse => childrenPerSpouse.spouseChildren.length > 0);

            setChildrenPerSpouses(childrenPerSpouses);
        }
        setLoading(!individual || false);
    }, [familySpouses, individualService, individual]);

    if (!loading) {
        return (
            <section hidden={childrenPerSpouses.length === 0}>
                <h2>Enfants</h2>

                {
                    childrenPerSpouses.map((childrenPerSpouse, idx) => {
                        return <ChildrenPerSpouseSection key={idx} {...childrenPerSpouse}></ChildrenPerSpouseSection>
                    })
                }

            </section>
        );
    } else {
        return (
            <section>
                <h2>Enfants</h2>

                <IndividualGallery>
                    <IndividualCardPlaceholder></IndividualCardPlaceholder>
                    <IndividualCardPlaceholder></IndividualCardPlaceholder>
                    <IndividualCardPlaceholder></IndividualCardPlaceholder>
                </IndividualGallery>

            </section>
        );
    }

}

function ChildrenPerSpouseSection(props: { spouse?: Individual, spouseChildren: Individual[] }) {
    return <>
        {props.spouse && <p>Avec {props.spouse.name}</p>}
        <IndividualGallery>
            {
                props.spouseChildren.map(c => <IndividualCard key={c.id} individual={c}></IndividualCard>)
            }
        </IndividualGallery>
    </>
}
