import {Individual} from "../../../model/Individual";
import React, {useContext, useEffect, useState} from "react";
import {AppServiceContext} from "../../../providers/AppServiceProvider/AppServiceProvider";
import {Family} from "../../../model/Family";
import IndividualGallery from "../../../components/IndividualGallery/IndividualGallery";
import IndividualCard from "../../../components/IndividualCard/IndividualCard";
import useFamilyEventFormatter from "../../../hooks/useFamilyEventFormatter";
import IndividualCardPlaceholder from "../../../components/IndividualCard/IndividualCardPlaceholder";

export default function SpousesSection({individual}: { individual?: Individual }) {
    const {individualService, familyService} = useContext(AppServiceContext);
    const familyEventFormatter = useFamilyEventFormatter();

    const [familySpouses, setFamilySpouses] = useState<Family[]>([]);
    const [spouses, setSpouses] = useState<{ [id: string]: Individual }>({});
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (individual) {
            const familySpouses = individual.familySpouses
                .map(spouse => familyService.findOne(spouse))
                .filter(spouse => spouse?.husband && spouse.wife)
                .filter(spouse => !!spouse) as Family[];

            const spouses: { [id: string]: Individual; } = familySpouses.reduce((spousesDict, family) => {
                const s = family.husband === individual.id ? family.wife : family.husband;

                if (s) {
                    const spouse = individualService.findOne(s);
                    if (spouse) {
                        spousesDict[family.id] = spouse;
                    }
                }
                return spousesDict;
            }, {} as { [id: string]: Individual; });

            setFamilySpouses(familySpouses);
            setSpouses(spouses);
            setLoading(!individual || false);
        }

    }, [individual, familyService, individualService]);

    if (!loading) {
        return <section hidden={individual?.familySpouses.length === 0}>
            <h2>Conjoints</h2>

            <IndividualGallery>
                {
                    spouses && familySpouses.map((f) => {
                        const extras = [];
                        f.wedding && extras.push('se sont mariés ' + familyEventFormatter(f.wedding, true));
                        f.divorce && extras.push('ont divorcés ' + familyEventFormatter(f.divorce, true));

                        return <IndividualCard key={f.id} individual={spouses[f.id]} options={{
                            civilityTitle: false,
                            infos: ['birth', 'death'],
                            extras: extras,
                        }}></IndividualCard>;
                    })
                }
            </IndividualGallery>

        </section>;
    } else {
        return <section>
            <h2>Conjoints</h2>

            <IndividualGallery>
                <IndividualCardPlaceholder></IndividualCardPlaceholder>
            </IndividualGallery>

        </section>;

    }

};
