import {FamilyEvent} from "./FamilyEvent";

export type Sex = 'male' | 'female';

export interface Individual {
    id: string;
    name: string;
    // Nom de famille
    surname?: string;
    // Prénom
    givenName?: string;
    sex?: Sex;
    birth?: FamilyEvent;
    death?: FamilyEvent;
    familyChild?: string;
    familySpouses: string[];
    occupations: string[];
}


export function byNameComparator(i1?: Individual, i2?: Individual): number {
    if (i1?.name && i2?.name) {
        return i1.name.localeCompare(i2.name)
    } else if (i1?.name) {
        return -1;
    } else if (i2?.name) {
        return 1;
    } else {
        return 0;
    }
}

export function byBirthDateComparator(i1?: Individual, i2?: Individual): number {
    if (i1?.birth?.date && i2?.birth?.date) {
        return i1.birth.date.localeCompare(i2.birth.date);
    } else if (i1?.birth?.date) {
        return -1;
    } else if (i2?.birth?.date) {
        return 1;
    } else {
        return 0;
    }
}

export function byTextMatchNamePredicate(text: string): (i: Individual) => boolean {
    const regexes = text.split(' ')
        .filter(token => token.trim().length > 0)
        .map(token => `.*${token}.*`)
        .map(regex => new RegExp(regex, 'i'));

    return i => {
        return regexes.map(regex => i.name.match(regex))
            .reduce((previousValue, currentValue): boolean => {
                return previousValue && !!currentValue;
            }, true);
    }
}

