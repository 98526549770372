import {Individual} from "../model/Individual";
import {useContext, useEffect, useMemo, useState} from "react";
import {AppGedcomContext} from "../providers/AppGedcomProvider/AppGedcomProvider";
import {toIndividual} from "../model/mapper";


const useIndividualService = () => {
    const [individuals, setIndividuals] = useState<Individual[]>([]);

    const gedcom = useContext(AppGedcomContext);

    useEffect(() => {
        setIndividuals(gedcom.filter(gedcomEntry => gedcomEntry.tag === 'INDI').map(toIndividual));
    }, [gedcom]);

    return useMemo(() => {
        return {
            findAll: (options?: {sortBy?: (i1: Individual, i2: Individual) => number, filterBy?: (i: Individual) => boolean}) => {
                let all = individuals;

                if (options?.filterBy) {
                    all = all.filter(options.filterBy);
                }

                if (options?.sortBy) {
                    all = all.sort(options.sortBy);
                }
                return all;
            },
            findOne: (id: string) => individuals.filter(individual => individual.id === id).pop()
        }
    }, [individuals]);
}

export default useIndividualService;
