import {useContext, useEffect, useMemo, useState} from "react";
import {Family} from "../model/Family";
import {AppGedcomContext} from "../providers/AppGedcomProvider/AppGedcomProvider";
import {toFamily} from "../model/mapper";


function useFamilyService() {
    const gedcom = useContext(AppGedcomContext);

    const [families, setFamilies] = useState<Family[]>([]);

    useEffect(() => {
        setFamilies(gedcom.filter(gedcomEntry => gedcomEntry.tag === 'FAM').map(toFamily));
    }, [gedcom]);

    return useMemo(() => {
        return {
            findAll: () => families,
            findOne: (id: string) => families.filter(family => family.id === id).pop()
        }
    }, [families]);
}

export default useFamilyService;
