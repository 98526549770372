import React, {createContext, PropsWithChildren} from "react";
import {Individual} from "../../model/Individual";
import useIndividualService from "../../hooks/useIndividualService";
import {Family} from "../../model/Family";
import useFamilyService from "../../hooks/useFamilyService";
import {Relationship} from "../../model/Relationship";
import useRelationshipService from "../../hooks/useRelationshipService";

interface ServiceContext {
    individualService: {
        findAll: (options?: {sortBy?: (i1: Individual, i2: Individual) => number, filterBy?: (i: Individual) => boolean}) => Individual[],
        findOne: (id: string) => Individual | undefined
    },
    familyService: {
        findAll: () => Family[],
        findOne: (ide: string) => Family | undefined
    },
    relationshipService: {
        findAll: () => Relationship[],
    },
}

export const AppServiceContext = createContext<ServiceContext>({
    individualService: {
        findAll: () => [],
        findOne: () => undefined
    },
    familyService: {
        findAll: () => [],
        findOne: () => undefined
    },
    relationshipService: {
        findAll: () => [],
    }
});

function AppServiceProvider(props: PropsWithChildren) {
    const individualService = useIndividualService();
    const familyService = useFamilyService();
    const relationshipService = useRelationshipService();

    return <AppServiceContext.Provider value={{individualService, familyService, relationshipService}}>
        {props.children}
    </AppServiceContext.Provider>
}

export default AppServiceProvider;
