import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";
import {Individual} from "../../model/Individual";
import {FormattedMessage} from "react-intl";

export default function IndividualDistanceBadge({individual, distance}: {individual?: Individual, distance?: number}) {
    const bg = individual?.sex === 'female' ? 'badge-female' : 'badge-male';
    return individual ? <OverlayTrigger placement="top" overlay={
        <Tooltip>
            <FormattedMessage id="IndividualDistanceBadge.distance" values={{
                distance: distance,
                name: individual.name
            }}></FormattedMessage>
        </Tooltip>
    }>
        <Badge pill bg={bg}>{distance}</Badge>
    </OverlayTrigger> : null;
};
