import React, {PropsWithChildren} from "react";
import {Container, Placeholder} from "react-bootstrap";

function AppPagePlaceholder(props: PropsWithChildren) {
    return (
        <Container as="main" className="mb-3">
            <Placeholder as={'h1'} animation="glow">
                <Placeholder xs={6} />
            </Placeholder>
            {props.children}
        </Container>
    );
}

export default AppPagePlaceholder;
