import {byBirthDateComparator, Individual} from "../../../model/Individual";
import React, {useContext, useEffect, useState} from "react";
import {AppServiceContext} from "../../../providers/AppServiceProvider/AppServiceProvider";
import {Family} from "../../../model/Family";
import IndividualGallery from "../../../components/IndividualGallery/IndividualGallery";
import IndividualCard from "../../../components/IndividualCard/IndividualCard";
import IndividualCardPlaceholder from "../../../components/IndividualCard/IndividualCardPlaceholder";

export default function SiblingsSection(props: { individual?: Individual }) {
    const {individualService, familyService} = useContext(AppServiceContext);

    const [familyChild, setFamilyChild] = useState<Family | undefined>();
    const [siblings, setSiblings] = useState<Individual[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (props.individual?.familyChild) {
            setFamilyChild(familyService.findOne(props.individual.familyChild));
        } else {
            setFamilyChild(undefined);
        }
    }, [props.individual, familyService]);

    useEffect(() => {
        if (familyChild) {
            const siblings = familyChild.children
                .filter(child => child !== props.individual?.id)
                .map(sibling => individualService.findOne(sibling))
                .filter(sibling => !!sibling)
                .sort(byBirthDateComparator) as Individual[];

            setSiblings(siblings);

        } else {
            setSiblings([]);
        }
        setLoading(!props.individual || false);
    }, [familyChild, individualService, props.individual]);

    if (!loading) {
        return <section hidden={siblings.length === 0}>
            <h2>Fratrie</h2>

            <IndividualGallery>
                {
                    siblings.map(s => <IndividualCard key={s.id} individual={s}></IndividualCard>)
                }
            </IndividualGallery>
        </section>;
    } else {
        return <section>
            <h2>Fratrie</h2>

            <IndividualGallery>
                <IndividualCardPlaceholder></IndividualCardPlaceholder>
                <IndividualCardPlaceholder></IndividualCardPlaceholder>
                <IndividualCardPlaceholder></IndividualCardPlaceholder>
            </IndividualGallery>
        </section>;
    }

};

