import {useContext, useEffect, useMemo, useState} from "react";
import {AppServiceContext} from "../providers/AppServiceProvider/AppServiceProvider";
import {Individual} from "../model/Individual";
import {Relationship} from "../model/Relationship";


interface FamilyGraph {
    I: Individual[],
    R: Relationship[]
}

function useIndividualRelationsService(individualId?: string): { distances: { [individualId: string]: number }, findPathTo: (individualId: string) => Relationship[] } {
    const {individualService, relationshipService} = useContext(AppServiceContext);
    const [G, setG] = useState<FamilyGraph>({I: [], R: []});
    const [distances, setDistances] = useState<{ [individualId: string]: number }>({});
    const [predecessors, setPredecessors] = useState<{ [individualId: string]: Relationship | null }>({});

    useEffect(() => {
        const individuals = individualService.findAll();
        const relationships = relationshipService.findAll();
        setG({I: individuals, R: relationships});
    }, [individualService, relationshipService]);

    useEffect(() => {
        let distances: { [individualId: string]: number } = {};
        let predecessors: { [individualId: string]: Relationship | null } = {};

        function init(G: FamilyGraph, i0: string) {
            distances = G.I.map(i => i.id)
                .reduce((d, id) => {
                    d[id] = Number.MAX_SAFE_INTEGER;
                    return d;
                }, {} as { [individualId: string]: number });

            predecessors = G.I.map(i => i.id)
                .reduce((p, id) => {
                    p[id] = null;
                    return p;
                }, {} as { [individualId: string]: Relationship | null });

            distances[i0] = 0;
        }

        function findMin(F: Individual[]): Individual | undefined {
            let min = Number.MAX_SAFE_INTEGER;
            let individual: Individual | undefined = undefined;
            F.forEach((i) => {
                if (distances[i.id] < min) {
                    min = distances[i.id];
                    individual = i;
                }
            });
            return individual;
        }

        function updateDistance(relationship: Relationship) {
            if (distances[relationship.i2] > distances[relationship.i1] + 1) {
                distances[relationship.i2] = distances[relationship.i1] + 1;
                predecessors[relationship.i2] = relationship;
            }
        }

        function dijkstra(G: FamilyGraph, i0: string) {
            init(G, i0);

            const F = G.I.map(i => i);

            while (F.length > 0) {
                const i1 = findMin(F);
                if (i1) {
                    F.splice(F.indexOf(i1), 1);

                    G.R.filter(r => r.i1 === i1.id)
                        .forEach(r => updateDistance(r));
                } else {
                    throw Error();
                }
            }
        }

        if (individualId && G.I.length > 0 && G.R.length > 0) {
            dijkstra(G, individualId);

            setDistances(distances);
            setPredecessors(predecessors);
        } else {
            setDistances({});
            setPredecessors({});
        }
    }, [G, individualId]);

    return useMemo(() => {
        return {
            distances: distances,
            findPathTo: iId => {
                if (Object.keys(predecessors).length > 0) {
                    const path = [];
                    let i = iId;
                    while (i !== individualId) {
                        const predecessor = predecessors[i];
                        if (predecessor) {
                            path.push(predecessor);
                            i = predecessor.i1
                        }
                    }

                    return path.reverse();
                } else {
                    return [];
                }
            }
        }
    }, [individualId, distances, predecessors]);
}


export default useIndividualRelationsService;
