import {Accordion, Button} from "react-bootstrap";
import React, {PropsWithChildren, useContext} from "react";
import {Download} from "react-bootstrap-icons";
import AppPage from "../AppPage/AppPage";
import {AppContext} from "../../App";


function FilesPage() {

    const {gedcomUrl, graphSvgUrl, graphPngUrl, graphPdfUrl} = useContext(AppContext);


    const downloads = {
        gedcomFiles: [
            {path: gedcomUrl, title: "Télécharger le fichier GEDCOM"}
        ],
        graphFiles: [
            {path: graphSvgUrl, title: "Télécharger au format vectoriel (SVG)"},
            {path: graphPngUrl, title: "Télécharger au format matriciel (PNG)"},
            {path: graphPdfUrl, title: "Télécharger au format document (PDF)"},
        ],
    }

    return (
        <AppPage id="files-page" title="Les fichiers">
            <Accordion defaultActiveKey={['2']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Le fichier GEDCOM</Accordion.Header>

                    <Accordion.Body>
                        <p>
                            La généalogie de la famille Samson est conservée dans un fichier de référence
                            au <a target="_blank" href="https://fr.wikipedia.org/wiki/GEDCOM" rel="noreferrer">format GEDCOM</a>.

                            L&apos;outil utilisé pour manipuler le fichier GEDCOM
                            est <a href="https://ancestris.org/">Ancestris 11</a>.
                        </p>

                        <DownloadButtonGroup downloads={downloads.gedcomFiles}></DownloadButtonGroup>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Les fichiers de l&apos;arbre familiale</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            L&apos;arbre familiale vous est proposé dans différentes saveurs.
                        </p>

                        <DownloadButtonGroup downloads={downloads.graphFiles}></DownloadButtonGroup>

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </AppPage>
    );
}

function DownloadButtonGroup(props: {downloads: {path: string ; title: string}[]}) {
    return <div className="d-grid gap-2 d-lg-flex justify-content-lg-end mb-3">
        {
            props.downloads.map((download, idx) => <DownloadButton key={idx} href={download.path}>{download.title}</DownloadButton>)
        }
    </div>;
}

function DownloadButton(props: PropsWithChildren<{href: string}>) {
    return <Button variant="outline-primary" href={props.href} target="_blank"><Download></Download> {props.children}</Button>
}

export default FilesPage;
