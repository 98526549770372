import React, {PropsWithChildren} from "react";
import {Container} from "react-bootstrap";

interface Props {
    id: string;
    title: React.ReactNode;
}

function AppPage(props: PropsWithChildren<Props>) {
    return (
        <Container as="main" id={props.id} className="mb-3">
            <h1>{props.title}</h1>
            {props.children}
        </Container>
    );
}

export default AppPage;
