import {Individual} from "../../../model/Individual";
import React, {useContext, useEffect, useState} from "react";
import {AppServiceContext} from "../../../providers/AppServiceProvider/AppServiceProvider";
import useApproximateDateFormatter from "../../../hooks/useApproximateDateFormatter";

export default function LifePart(props: { fernandSamson?: Individual }) {
    const {individualService, familyService} = useContext(AppServiceContext);
    const approximateDateFormatter = useApproximateDateFormatter();
    const [fernandDeathDate, setFernandDeathDate] = useState<string | undefined>();
    const [eugenieDeathDate, setEugenieDeathDate] = useState<string | undefined>();

    useEffect(() => {
        setFernandDeathDate(approximateDateFormatter(props.fernandSamson?.death?.date, true));
    }, [props.fernandSamson, approximateDateFormatter]);

    useEffect(() => {
        if (props.fernandSamson) {

            const eugenieRobin = props.fernandSamson.familySpouses
                .map(familySpouse => familyService.findOne(familySpouse))
                .map(familySpouse => individualService.findOne(familySpouse?.wife || ''))
                .pop();
            setEugenieDeathDate(approximateDateFormatter(eugenieRobin?.death?.date, true));
        }

        setFernandDeathDate(approximateDateFormatter(props.fernandSamson?.death?.date, true));
    }, [props.fernandSamson, approximateDateFormatter, individualService, familyService]);

    return <section>
        <p>
            Afin d&apos;assurer la subsistance d&apos;une telle famille, Fernand SAMSON multiplia ses activités.
            On ne sait ni où, ni quand, ni comment, il apprit la musique et l&apos;art de la flûte.
            Tout en conservant son métier de graveur sur bois,
            il donnait des cours de musique et de flûte à domicile et à l&apos;école Saint Nicolas, rue de Vaugirard,
            et le soir jouait dans un orchestre en théâtre ou music-hall de Montparnasse (souvent BOBINO).
        </p>
        <p>
            A ce régime, il décéda {fernandDeathDate}, âgé de 55 ans,
            laissant à son épouse, Eugénie, le soin de mener la barque familiale,
            ce qu&apos;elle assura d&apos;une maîtresse poigne avec le soutien actif des ainés des enfants
            sans aucune allocation familiale ni protection sociale.
        </p>
        <p>
            Elle reçut en 1920 la médaille de la FAMILLE FRANÇAISE.
        </p>
        <p>
            Elle décéda à son tour {eugenieDeathDate}, agée de 91 ans.
        </p>
    </section>;
};

