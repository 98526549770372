import {Individual} from "../../../model/Individual";
import React, {useContext, useEffect, useState} from "react";
import {AppServiceContext} from "../../../providers/AppServiceProvider/AppServiceProvider";
import {Family} from "../../../model/Family";
import IndividualGallery from "../../../components/IndividualGallery/IndividualGallery";
import IndividualCard from "../../../components/IndividualCard/IndividualCard";
import IndividualCardPlaceholder from "../../../components/IndividualCard/IndividualCardPlaceholder";

export default function ParentsSection(props: { individual?: Individual }) {
    const {individualService, familyService} = useContext(AppServiceContext);
    const [familyChild, setFamilyChild] = useState<Family | undefined>();
    const [father, setFather] = useState<Individual | undefined>();
    const [mother, setMother] = useState<Individual | undefined>();
    const [parents, setParents] = useState<Individual[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (props.individual?.familyChild) {
            setFamilyChild(familyService.findOne(props.individual.familyChild));
        } else {
            setFamilyChild(undefined);
        }
    }, [props.individual, familyService]);

    useEffect(() => {
        if (familyChild && familyChild.husband) {
            setFather(individualService.findOne(familyChild.husband));
        } else {
            setFather(undefined);
        }
    }, [individualService, familyChild]);

    useEffect(() => {
        if (familyChild && familyChild.wife) {
            const wife = individualService.findOne(familyChild.wife);
            setMother(wife);
        } else {
            setMother(undefined);
        }
    }, [individualService, familyChild]);

    useEffect(() => {
        const parents: Individual[] = [];
        father && parents.push(father);
        mother && parents.push(mother);

        setParents(parents);
        setLoading(!props.individual || false);
    }, [father, mother, props.individual]);

    if (!loading) {
        return <section hidden={!father && !mother} className="mb-3">
            <h2>Parents</h2>

            <IndividualGallery>
                {
                    parents.map(p => <IndividualCard key={p.id} individual={p}></IndividualCard>)
                }
            </IndividualGallery>
        </section>;
    } else {
        return <section className="mb-3">
            <h2>Parents</h2>

            <IndividualGallery>
                <IndividualCardPlaceholder></IndividualCardPlaceholder>
                <IndividualCardPlaceholder></IndividualCardPlaceholder>
            </IndividualGallery>
        </section>;
    }
}
