import {Button, Container, Form, Nav, Navbar} from "react-bootstrap";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import React, {FormEvent, useEffect, useRef} from "react";
import {Search, Tree} from "react-bootstrap-icons";

const sections = [
    {id: 'family-root', to: '/racines', title: 'Les Racines'},
    {id: 'individuals', to: '/individus', title: 'Les Individus'},
    {id: 'family-tree', to: '/arbre', title: 'L\'Arbre'},
    {id: 'files', to: '/fichiers', title: 'Les Fichiers'},
    {id: 'about', to: '/a-propos', title: 'A Propos'},
];

function AppNav() {
    const [searchParams] = useSearchParams({recherche: ''});
    const navigate = useNavigate();
    const searchInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (searchInput.current) {
            searchInput.current.value = searchParams.get('recherche') || '';
        }
    }, [searchParams]);

    useEffect(() => {
        searchInput.current?.focus();
    }, [searchInput]);

    const handleSearchSumit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const query = searchInput.current?.value || '';
        navigate(`/individus?recherche=${query}`)
    }

    return (
        <Navbar id="navbar" fixed="top" expand="lg" variant="dark" bg="dark">
            <Container>
                <Navbar.Brand as={NavLink} to="/#">
                    <Tree color="#d63384" className="me-1"></Tree>
                    Les Cousins
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav"></Navbar.Toggle>
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="me-auto">
                        {
                            sections.map(section => {
                                return <Nav.Link as={NavLink} key={section.id} to={section.to}>
                                    {section.title}
                                </Nav.Link>;

                            })
                        }
                    </Nav>
                    <Form className="d-flex" onSubmit={handleSearchSumit}>
                        <Form.Control
                            type="search"
                            ref={searchInput}
                            placeholder="Rechercher"
                            className="me-2"
                            aria-label="Rechercher"
                        />
                        <Button variant="outline-light" type="submit">
                            <Search></Search>
                        </Button>


                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default AppNav;
