import {Col, Row} from "react-bootstrap";
import React, {PropsWithChildren, ReactElement} from "react";
import {IndividualCardProps} from "../IndividualCard/IndividualCard";

function IndividualGallery(props: PropsWithChildren<{ children?: ReactElement<IndividualCardProps>[] | ReactElement<IndividualCardProps> }>) {

    const children = props.children ? (
        Array.isArray(props.children) ? props.children : [props.children]
    ) : [];

    return <Row xs="1" sm="2" lg="3" xl="4" className="g-4 mb-3">
        {
            children.map((card, idx) => <Col key={idx}>{card}</Col>)
        }
    </Row>;
}

export default IndividualGallery;
