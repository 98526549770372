import {Individual} from "../../model/Individual";
import {useIntl} from "react-intl";
import useFamilyEventFormatter from "../../hooks/useFamilyEventFormatter";
import React, {useCallback} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";

export default function IndividualSummary(props: { individual: Individual }) {
    const intl = useIntl();
    const familyEventFormatter = useFamilyEventFormatter();

    const genderFormatter = useCallback((sex?: string): string => {
        if (!!sex) {
            return intl.formatMessage(
                {id: 'IndividualPage.gender'},
                {sex: sex}
            );
        } else {
            return '';
        }
    }, [intl]);

    const occupationsFormatter = useCallback(
        (occupations: string[]) => occupations.join(', '),
        []
    );

    let cardClassName = 'mb-3';
    if (props.individual.sex) {
        cardClassName += ' border-' + props.individual.sex;
    }

    return <Card className={cardClassName}>
        <Card.Body>

            <Form>

                {
                    [
                        {
                            controlId: 'formId',
                            label: 'Identifiant',
                            formattedValue: props.individual.id || '',
                        },
                        {
                            controlId: 'formName',
                            label: 'Nom complet',
                            formattedValue: props.individual.name || '',
                        },
                        {
                            controlId: 'formSurname',
                            label: 'Nom de famille',
                            formattedValue: props.individual.surname || '',
                        },
                        {
                            controlId: 'formGiveName',
                            label: 'Prénom',
                            formattedValue: props.individual.givenName || '',
                        },
                        {
                            controlId: 'formBirthDate',
                            label: 'Naissance',
                            formattedValue: familyEventFormatter(props.individual.birth, true),
                        },
                        {
                            controlId: 'formGender',
                            label: 'Genre',
                            formattedValue: genderFormatter(props.individual.sex),
                        },
                        {
                            controlId: 'formOccupations',
                            label: 'Occupations',
                            formattedValue: occupationsFormatter(props.individual.occupations),
                        },
                        {
                            controlId: 'formDeathDate',
                            label: 'Décès',
                            formattedValue: familyEventFormatter(props.individual.death, true),
                        },
                    ].filter(formGroup => !!formGroup.formattedValue)
                        .map(formGroup => <IndividualFormGroup {...formGroup} key={formGroup.controlId}/>)
                }
            </Form>
        </Card.Body>
    </Card>;
}

function IndividualFormGroup(props: { controlId: string; label: string; formattedValue: string }) {

    return <Form.Group as={Row} className="mb-3" controlId={props.controlId}>
        <Form.Label column sm={3}>{props.label}</Form.Label>
        <Col sm={9}>
            <Form.Control type="text" readOnly plaintext value={props.formattedValue || ''}/>
        </Col>
    </Form.Group>
}


