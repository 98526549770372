import React from "react";
import AppPage from "../AppPage/AppPage";

function AboutPage() {
    return (
        <AppPage id="about-page" title="A propos">
            <p>
                C&apos;est à l&apos;oncle André que l&apos;on doit les premiers travaux de collecte
                des informations pour constituer l&apos;arbre généalogique de la famille SAMSON.

                Il remettait à jour régulièrement le tableau qu&apos;il avait dessiné en 1986
                et ce jusqu&apos;en 2003.
            </p>

            <p>
                Hervé a fait des recherches pour retrouver
                les racines de la famille SAMSON. Il a pu remonter à un lointain ancêtre
                né  à l&apos;époque de Louis XIV.

                L&apos;informatique aidant, Hervé a prolongé ce travail :

                Le long tableau exposé lors de la cousinade des 9 et 10 mai 2015
                et la version papier distribuée par Gérard à cette même occasion
                en témoignes.
            </p>

            <p>
                Motivé par la cousinade des 4 et 5 mai 2022,
                Sébastien a entrepris de mettre en ligne le site Les Cousins.
            </p>

            <p>
                Ce travail n&apos;est certainement pas exempt d&apos;erreurs ou d&apos;omissions,
                veuillez nous en excuser,
                mais suivant la formule consacrée : &quot;Nos lecteurs rectifieront d&apos;eux-même&quot;.
            </p>

        </AppPage>
    );
}

export default AboutPage;
