import {Individual} from "../../model/Individual";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import useFamilyEventFormatter from "../../hooks/useFamilyEventFormatter";
import {Button, Card} from "react-bootstrap";
import {GenderFemale, GenderMale, Person} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";
import IndividualDistanceBadge from "../IndividualDistanceBadge/IndividualDistanceBadge";

type Info = 'birth' | 'death' | 'occupations';

export interface IndividualCardProps {
    individual: Individual,
    distance?: number,
    options?: {
        civilityTitle?: boolean;
        infos?: Info[];
        extras?: string[];
    }
}

IndividualCard.defaultProps = {
    options: {
        civilityTitle: false,
        infos: ['birth', 'death'],
        extras: [],
    }
};

function IndividualCard(props: IndividualCardProps) {
    const intl = useIntl();
    const navigate = useNavigate();

    const familyEventFormatter = useFamilyEventFormatter();

    const [name, setName] = useState<string>('');
    const [sentences, setSentences] = useState<string[]>([]);

    useEffect(() => {
        setName(
            intl.formatMessage(
                {id: 'IndividualCard.name'},
                {
                    sex: props.options?.civilityTitle && props.individual.sex,
                    name: props.individual.name
                }
            )
        );
    }, [props?.options?.civilityTitle, props.individual.sex, props.individual.name, intl]);

    useEffect(() => {

        const sentences = props?.options?.infos?.map(info => {
            let sentence;
            if (info === 'birth' && props.individual.birth && (props.individual.birth.date || props.individual.birth.place)) {
                sentence = intl.formatMessage(
                    {id: 'IndividualCard.birth'},
                    {
                        sex: props.individual.sex,
                        formattedEvent: familyEventFormatter(props.individual.birth, true)
                    }
                )
            } else if (info === 'death' && props.individual.death && (props.individual.death.date || props.individual.death.place)) {
                sentence = intl.formatMessage(
                    {id: 'IndividualCard.death'},
                    {
                        sex: props.individual.sex,
                        formattedEvent: familyEventFormatter(props.individual.death, true)
                    }
                )
            } else if (info === 'occupations' && props.individual.occupations.length > 0) {
                sentence = props.individual.occupations.map(o => o.toLocaleLowerCase(intl.locale)).join(', ');
            }

            return sentence;
        }).filter(sentence => !!sentence) as string[];

        setSentences([...sentences, ...(props.options?.extras || [])]);
    }, [intl, familyEventFormatter, props.individual, props.options?.infos, props.options?.extras])


    let cardClassName = '';
    if (props.individual.sex) {
        cardClassName += ' border-' + props.individual.sex;
    }

    let cardIcon;
    let buttonVariant;
    if (props.individual.sex === 'male') {
        cardIcon = <GenderMale className="male"></GenderMale>;
        buttonVariant = 'outlined-male';
    } else if (props.individual.sex === 'female') {
        cardIcon = <GenderFemale className="female"></GenderFemale>;
        buttonVariant = 'outlined-female';
    } else {
        cardIcon = <GenderMale className="male"></GenderMale>;
        buttonVariant = 'outlined-male';
    }

    return (
        <Card className={cardClassName}>
            <Card.Body className="d-flex flex-column">
                <Card.Title className="d-flex text-center">
                    <div>{cardIcon}</div>
                    <div className="flex-grow-1 ms-2 me-2">{name}</div>
                    <div>
                        <IndividualDistanceBadge individual={props.individual} distance={props.distance}></IndividualDistanceBadge>
                    </div>
                </Card.Title>
                <Card.Text>
                    {
                        sentences?.length > 0 && <>
                            {
                                sentences.slice(1).reduce(
                                    (paragraph, child) => {
                                        return <>{paragraph}{','}<br></br>{child}</>;
                                    },
                                    <>{sentences[0]}</>
                                )
                            }
                            {'.'}
                      </>
                    }
                </Card.Text>
                <Card.Text className="text-end">
                    <Button variant={buttonVariant} onClick={() => navigate(`/individus/${props.individual.id}`)} >
                        Voir <Person></Person>
                    </Button>
                </Card.Text>
            </Card.Body>
        </Card>
    );

}

export default IndividualCard;
