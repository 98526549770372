import React, {createContext, PropsWithChildren} from "react";
import {GedcomEntry} from "parse-gedcom";
import useGedcom from "../../hooks/useGedcom";


export const AppGedcomContext = createContext<GedcomEntry[]>([])

function AppGedcomProvider(props: PropsWithChildren) {

    const gedcom = useGedcom();

    return <AppGedcomContext.Provider value={gedcom}>
        {props.children}
    </AppGedcomContext.Provider>;

}

export default AppGedcomProvider;
