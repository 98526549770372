import {useEffect, useMemo, useState} from "react";
import {Relationship} from "../model/Relationship";
import {Family} from "../model/Family";
import useFamilyService from "./useFamilyService";

const toRelationships: (family: Family) => Relationship[] = (family) => {
    const arcs: Relationship[] = [];
    if (family.husband && family.wife) {
        arcs.push(
            {
                family: family.id,
                i1: family.husband,
                relation: 'spouse',
                i2: family.wife
            }, {
                family: family.id,
                i1: family.wife,
                relation: 'spouse',
                i2: family.husband
            }
        );
    }

    family.children.forEach(child => {
        family.husband && arcs.push(
            {
                family: family.id,
                i1: family.husband,
                relation: 'father',
                i2: child
            },
            {
                family: family.id,
                i1: child,
                relation: 'child',
                i2: family.husband
            },
        );

        family.wife && arcs.push(
            {
                family: family.id,
                i1: family.wife,
                relation: 'mother',
                i2: child
            },
            {
                family: family.id,
                i1: child,
                relation: 'child',
                i2: family.wife
            },
        );

        arcs.push(
            ...family.children.filter(sibling => sibling !== child)
                .map(sibling => {
                    return {
                        i1: child,
                        relation: 'sibling',
                        i2: sibling
                    } as Relationship
                })
        )


    });

    return arcs;
};

export default function useRelationshipService() {
    const familyService = useFamilyService();

    const [relationships, setRelationships] = useState<Relationship[]>([]);

    useEffect(() => {
        setRelationships(
            familyService.findAll()
                .map(toRelationships)
                .flat()
        );

    }, [familyService]);

    return useMemo(() => {
        return {
            findAll: () => relationships,
        };
    }, [relationships]);
};
