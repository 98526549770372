import {byBirthDateComparator, Individual} from "../../../model/Individual";
import React, {useContext, useEffect, useState} from "react";
import {AppServiceContext} from "../../../providers/AppServiceProvider/AppServiceProvider";
import IndividualGallery from "../../../components/IndividualGallery/IndividualGallery";
import IndividualCard from "../../../components/IndividualCard/IndividualCard";
import IndividualCardPlaceholder from "../../../components/IndividualCard/IndividualCardPlaceholder";
import {AppUserContext} from "../../../providers/AppUserProvider/AppUserProvider";
import useIndividualRelationsService from "../../../hooks/useIndividualRelationsService";

export default function ChildrenPart(props: { fernandSamson?: Individual }) {
    const {individualService, familyService} = useContext(AppServiceContext);
    const {user: currentUser} = useContext(AppUserContext);

    const individualRelationsService = useIndividualRelationsService(currentUser?.id);

    const [children, setChildren] = useState<Individual[]>();

    useEffect(() => {
        if (props.fernandSamson) {
            const children = props.fernandSamson.familySpouses
                .map(familySpouse => familyService.findOne(familySpouse))
                .map(familySpouse => familySpouse?.children.map(child => individualService.findOne(child)))
                .flat()
                .filter(child => !!child)
                .sort(byBirthDateComparator) as Individual[];

            setChildren(children);
        }
    }, [props.fernandSamson, familyService, individualService]);

    return <section>
        <p>
            De cette union naquirent 14 enfants.
            Tous naquirent 89 rue du Cherche-Midi, Paris 6<sup>ème</sup> (sauf les trois ainés, nés rue Dragon).
        </p>

        <IndividualGallery>
            {
                children ?
                    children.map(child => <IndividualCard key={child.id} individual={child} distance={individualRelationsService.distances[child.id]}></IndividualCard>) :
                    (new Array(14).fill(0)).map(i => <IndividualCardPlaceholder key={i}></IndividualCardPlaceholder>)
            }
        </IndividualGallery>

    </section>;
};

