import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import IndividualsPage from "./routes/IndividualsPage/IndividualsPage";
import IndividualPage from "./routes/IndividualPage/IndividualPage";
import FamilyRootPage from "./routes/FamilyRootPage/FamilyRootPage";
import FamilyTreePage from "./routes/FamilyTreePage/FamilyTreePage";
import FilesPage from "./routes/FilesPage/FilesPage";
import AboutPage from "./routes/AboutPage/AboutPage";
import ScrollToTop from "./routes/ScrollTop/ScrollTop";
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Router>
            <ScrollToTop></ScrollToTop>
            <Routes>
                <Route path="/" element={<App />} >

                    <Route index element={<Navigate to="racines"></Navigate>} />

                    <Route path="racines" element={<FamilyRootPage></FamilyRootPage>} />
                    <Route path="arbre" element={<FamilyTreePage></FamilyTreePage>} />
                    <Route path="fichiers" element={<FilesPage></FilesPage>} />
                    <Route path="a-propos" element={<AboutPage></AboutPage>} />
                    <Route path="individus" element={<IndividualsPage></IndividualsPage>} />
                    <Route path="individus/:id" element={<IndividualPage></IndividualPage>} />
                </Route>
            </Routes>
        </Router>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
