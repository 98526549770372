import AppPage from "../AppPage/AppPage";
import {Individual} from "../../model/Individual";
import {Image} from "react-bootstrap";
import arbre from "./arbre.jpeg";
import React, {useContext, useEffect, useState} from "react";
import {AppServiceContext} from "../../providers/AppServiceProvider/AppServiceProvider";
import WeddingPart from "./WeddingPart/WeddingPart";
import ChildrenPart from "./ChildrenPart/ChildrenPart";
import LifePart from "./LifePart/LifePart";

const fernandSamsonId = 'I00050';

function FamilyRootPage() {
    const {individualService} = useContext(AppServiceContext);
    const [fernandSamson, setFernandSamson] = useState<Individual | undefined>();

    useEffect(() => {
        setFernandSamson(individualService.findOne(fernandSamsonId));
    }, [individualService]);

    return (
        <AppPage id="family-root-page" title="Les racines de la famille Samson">
            <header className="mb-3">
                <div className="d-flex flex-row justify-content-center">
                    <Image src={arbre} alt="Arbre" fluid></Image>
                </div>
            </header>


            <main className="d-flex flex-column">
                <WeddingPart fernandSamson={fernandSamson}></WeddingPart>

                <ChildrenPart fernandSamson={fernandSamson}></ChildrenPart>

                <LifePart fernandSamson={fernandSamson}></LifePart>
            </main>

        </AppPage>
    );
}

export default FamilyRootPage;
