import {GedcomEntry} from "parse-gedcom";
import {FamilyEvent} from "./FamilyEvent";
import {Family} from "./Family";
import {Individual, Sex} from "./Individual";

const approximateDatePattern = /(([1-3]?\d)\s)?((JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)\s)?(\d{4})/;

const gedcomMonthToIso: { [gedcomMonth: string]: string } = {
    'JAN': '01',
    'FEB': '02',
    'MAR': '03',
    'APR': '04',
    'MAY': '05',
    'JUN': '06',
    'JUL': '07',
    'AUG': '08',
    'SEP': '09',
    'OCT': '10',
    'NOV': '11',
    'DEC': '12'
};

export const toData = (dataEntry?: GedcomEntry): string | undefined => {
    return dataEntry?.data;
}

export const toIsoDate = (dateEntry?: GedcomEntry): string | undefined => {

    const data = toData(dateEntry);
    const result = data && approximateDatePattern.exec(data);
    if (result) {
        let isoDate = result[5];

        const gedcomMonth = result[4];
        isoDate += gedcomMonth ? '-' + gedcomMonthToIso[gedcomMonth] : '';

        const gedcomDay = result[2];
        isoDate += gedcomDay ? '-' + gedcomDay.padStart(2, '0') : '';

        return isoDate;
    } else {
        return undefined;
    }
};

export const parsePointer = (pointer: string): string => {
    return pointer.replaceAll('@', '');
}

export const toId = (pointerEntry?: GedcomEntry): string | undefined => {
    return pointerEntry?.pointer && parsePointer(pointerEntry.pointer);
}

export const toFamilyEvent = (entry: GedcomEntry): FamilyEvent => {
    return {
        date: toIsoDate(entry.tree.find(e => e.tag === 'DATE')),
        place: toData(entry.tree.find(e => e.tag === 'PLAC')),
    }
}

export const toFamily = (familyEntry: GedcomEntry): Family => {
    const husbandPointer = toData(familyEntry.tree.find(entry => entry.tag === 'HUSB'));
    const wifePointer = toData(familyEntry.tree.find(entry => entry.tag === 'WIFE'));
    return {
        id: parsePointer(familyEntry.pointer),

        husband: husbandPointer && parsePointer(husbandPointer),

        wife: wifePointer && parsePointer(wifePointer),

        children: familyEntry.tree
            .filter(entry => entry.tag === 'CHIL')
            .map(entry => toData(entry))
            .map(data => data && parsePointer(data))
            .filter(pointer => !!pointer) as string[],

        wedding: familyEntry.tree
            .filter(entry => entry.tag === 'MARR')
            .map(weddingEntry => toFamilyEvent(weddingEntry))
            .pop(),
        divorce: familyEntry.tree
            .filter(entry => entry.tag === 'DIV')
            .map(divorceEntry => toFamilyEvent(divorceEntry))
            .pop(),
    };
}

const toSex = (sexEntry?: GedcomEntry): Sex | undefined => {
    return toData(sexEntry) === 'F' ? 'female' : 'male';
}

export const toIndividual = (individualEntry: GedcomEntry): Individual => {
    const nameEntry = individualEntry.tree
        .find(entry => entry.tag === 'NAME') || {data: 'Anonyme', tree: []};

    const familyChildPointer = toData(individualEntry.tree.find(entry => entry.tag === 'FAMC'));
    return {
        id: parsePointer(individualEntry.pointer),
        sex: toSex(individualEntry.tree.find(entry => entry.tag === 'SEX')),
        // TODO move naming in an object
        name: nameEntry.data.replaceAll('/', ''),
        surname: toData(nameEntry.tree.find(entry => entry.tag === 'SURN')),
        givenName: toData(nameEntry.tree.find(entry => entry.tag === 'GIVN')),

        birth: individualEntry.tree
            .filter(entry => entry.tag === 'BIRT')
            .map(birthEntry => toFamilyEvent(birthEntry))
            .pop(),

        death: individualEntry.tree
            .filter(entry => entry.tag === 'DEAT')
            .map(deathEntry => toFamilyEvent(deathEntry))
            .pop(),

        familyChild: familyChildPointer && parsePointer(familyChildPointer),

        familySpouses: individualEntry.tree
            .filter(entry => entry.tag === 'FAMS')
            .map(entry => toData(entry))
            .map(data => data && parsePointer(data))
            .filter(pointer => !!pointer) as string[],

        occupations: individualEntry.tree
            .filter(entry => entry.tag === 'OCCU')
            .map(entry => toData(entry))
            .filter(data => !!data) as string[],

    };
}

