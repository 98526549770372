import {GedcomEntry, parse as parseGedcom} from "parse-gedcom";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../App";


export default function useGedcom() {

    const {gedcomUrl} = useContext(AppContext);

    const [gedcomEntries, setGedcomEntries] = useState<GedcomEntry[]>([]);


    useEffect(() => {
        if (gedcomUrl) {
            fetch(gedcomUrl)
                .then(response => response.text())
                .then(gedcomText => parseGedcom(gedcomText))
                .then(gedcomEntries => setGedcomEntries(gedcomEntries));
        }
    }, [gedcomUrl]);

    return gedcomEntries;
}
