import {IntlShape, useIntl} from "react-intl";
import {useCallback} from "react";

function formatYear(year: string, prefix: boolean): string {
    return prefix ? `en ${year}` : year;
}

function formatYearAndMonth(yearAndMonth: string, prefix: boolean, intl: IntlShape): string {
    const date = new Date(yearAndMonth + '-01');
    const formattedDate = intl.formatDateToParts(date, {month: "long", year: "numeric"})
        .reduce((s, part) => s + part.value, '');
    return prefix ? `en ${formattedDate}` : formattedDate;
}

function formatDate(date: Date, prefix: boolean, intl: IntlShape): string {
    const formattedDate = intl.formatDate(date, {dateStyle: 'long'})
    return prefix ? `le ${formattedDate}` : formattedDate;
}


function useApproximateDateFormatter(): (approximateDate?: string, prefix?: boolean) => string {
    const intl = useIntl();

    return useCallback(
        (approximateDate?, prefix?: boolean): string => {
            if (approximateDate) {
                const approximateDateLength = approximateDate.length;
                if (approximateDateLength === '0000'.length) {
                    return formatYear(approximateDate, prefix || false);
                } else if (approximateDateLength === '0000-00'.length) {
                    return formatYearAndMonth(approximateDate, prefix || false, intl);
                } else if (approximateDateLength === '0000-00-00'.length) {
                    return formatDate(new Date(approximateDate), prefix || false, intl);
                } else {
                    return '';
                }
            } else {
                return '';
            }
        },
        [intl]
    );
}

export default useApproximateDateFormatter;
