import React, {createContext} from 'react';
import './App.css';
import AppNav from "./components/AppNav/AppNav";
import {Outlet} from "react-router-dom";
import {IntlProvider} from "react-intl";
import messages from "./locales/locale.fr.json"
import AppGedcomProvider from "./providers/AppGedcomProvider/AppGedcomProvider";
import AppServiceProvider from "./providers/AppServiceProvider/AppServiceProvider";
import AppUserProvider from "./providers/AppUserProvider/AppUserProvider";

const context = {
    gedcomUrl: process.env.PUBLIC_URL + '/assets/LesCousins.ged',
    graphSvgUrl: process.env.PUBLIC_URL + '/assets/LesCousins.svg',
    graphPngUrl: process.env.PUBLIC_URL + '/assets/LesCousins.png',
    graphPdfUrl: process.env.PUBLIC_URL + '/assets/LesCousins.pdf',
};

interface Context {
    gedcomUrl: string;
    graphSvgUrl: string;
    graphPngUrl: string;
    graphPdfUrl: string;
}
export const AppContext = createContext<Context>(context);

function App() {

    return (
        <IntlProvider locale="fr" messages={messages}>
            <AppContext.Provider value={context}>
                <AppGedcomProvider>
                    <AppServiceProvider>
                        <AppUserProvider>

                            <div className="App">
                                <AppNav></AppNav>

                                <Outlet></Outlet>
                            </div>

                        </AppUserProvider>
                    </AppServiceProvider>
                </AppGedcomProvider>
            </AppContext.Provider>
        </IntlProvider>
    );
}

export default App;
