import AppPage from "../AppPage/AppPage";
import IndividualGallery from "../../components/IndividualGallery/IndividualGallery";
import React, {useContext, useEffect, useRef, useState} from "react";
import {byNameComparator, byTextMatchNamePredicate, Individual} from "../../model/Individual";
import {useSearchParams} from "react-router-dom";
import {AppServiceContext} from "../../providers/AppServiceProvider/AppServiceProvider";
import IndividualSearchBar, {RefObject} from "../../components/IndividualSearchBar/IndividualSearchBar";
import IndividualCard from "../../components/IndividualCard/IndividualCard";
import IndividualCardPlaceholder from "../../components/IndividualCard/IndividualCardPlaceholder";
import useIndividualRelationsService from "../../hooks/useIndividualRelationsService";
import {AppUserContext} from "../../providers/AppUserProvider/AppUserProvider";

function IndividualsPage() {
    const {individualService} = useContext(AppServiceContext);
    const {user: currentUser} = useContext(AppUserContext);
    const individualRelationsService = useIndividualRelationsService(currentUser?.id);

    const [individuals, setIndividuals] = useState<Individual[]>();

    const [searchParams, setSearchParams] = useSearchParams({recherche: ''});

    const searchBar = useRef<RefObject>(null);

    useEffect(() => {
        const search = searchParams.get('recherche');
        const options = {
            filterBy: search ? byTextMatchNamePredicate(search) : undefined,
            sortBy: byNameComparator,
        };

        setIndividuals(individualService.findAll(options));
    }, [individualService, searchParams]);

    useEffect(() => {
        return searchBar.current?.focus();
    });

    return (
        <AppPage id="individuals-page" title="Les individus">
            <section id="search">

                <IndividualSearchBar ref={searchBar} currentSearch={searchParams.get('recherche') || ''} currentCount={individuals?.length}
                           onChange={search => setSearchParams({recherche: search})}></IndividualSearchBar>

            </section>

            <section id="gallery" className="mt-3" >
                <IndividualGallery>
                    {
                        individuals ?
                            individuals.map(i => <IndividualCard key={i.id} individual={i} distance={individualRelationsService.distances[i.id]}></IndividualCard>) :
                            (new Array(14).fill(0)).map(i => <IndividualCardPlaceholder key={i}></IndividualCardPlaceholder>)
                    }
                </IndividualGallery>
            </section>
        </AppPage>
    );
}


export default IndividualsPage;
