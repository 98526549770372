import React from 'react';
import './IndividualCardPlaceholder.css';
import {Card, Placeholder} from 'react-bootstrap';

interface Props {
    infoPlaceholders?: number
}

function IndividualCardPlaceholder({infoPlaceholders = 2}: Props) {

    const placeholderRandomSize: () => number = () => Math.ceil(Math.random() * 7);

    return (
        <Card className="card-placeholder">
            <Card.Body className="d-flex flex-column">
                <Placeholder as={Card.Title} className="d-flex text-center" animation="glow">
                    <Placeholder xs={8}></Placeholder>

                </Placeholder>
                <Placeholder as={Card.Text} animation="glow">
                    {
                        (new Array(infoPlaceholders).fill(0))
                            .map((zero, idx) => <span key={idx}>
                                <Placeholder xs={placeholderRandomSize()}></Placeholder><br/>
                            </span>)

                    }
                </Placeholder>
                <Placeholder as={Card.Text} className="text-end" animation="glow">
                    <Placeholder.Button variant="outline-primary">Voir</Placeholder.Button>
                </Placeholder>
            </Card.Body>
        </Card>
    );

}

export default IndividualCardPlaceholder;
