import {Individual} from "../../model/Individual";
import {createContext, PropsWithChildren} from "react";
import useLocalStorage from "../../hooks/useLocalStorage";

interface UserContext {
    user?: Individual;
    setUser: (user?: Individual) => void
}


export const AppUserContext = createContext<UserContext>({
    setUser: () => {}
});

export default function AppUserProvider({children}: PropsWithChildren) {
    const [value, setValue] = useLocalStorage<Individual>('user');

    return <AppUserContext.Provider value={{user: value, setUser: setValue}}>
        {children}
    </AppUserContext.Provider>
}
