import React, {forwardRef, Ref, useImperativeHandle, useRef} from "react";
import {Form} from "react-bootstrap";
import {BinocularsFill} from "react-bootstrap-icons";
import {FormattedMessage} from "react-intl";

interface Props {
    label?: string,
    currentSearch: string;
    currentCount?: number,
    onChange?: (search: string) => void
}

export interface RefObject {
    focus: () => void
}

const IndividualSearchBar = forwardRef((props: Props, ref: Ref<RefObject>) => {
    const searchControl = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
        focus() {
            searchControl.current?.focus();
        },
    }));

    return <Form onSubmit={e => e.preventDefault()}>
        <Form.Group controlId="searchForm.searchControl">
            <Form.Control ref={searchControl} type="search" placeholder={props.label}
                          value={props.currentSearch}
                          onChange={e => props.onChange && props.onChange(e.target.value)}/>
            <Form.Text>
                <BinocularsFill className="pe-1"></BinocularsFill>
                <FormattedMessage id="IndividualSearchBar.individualsFoundCount"
                                  values={{count: props.currentCount || '...'}}></FormattedMessage>
            </Form.Text>
        </Form.Group>
    </Form>;
});

IndividualSearchBar.defaultProps = {
    label: 'Rechercher ...'
};

export default IndividualSearchBar;
