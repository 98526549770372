import {useEffect, useState} from "react";


export default function useLocalStorage<T>(storageKey: string, fallbackState?: T): [T | undefined, (t?: T) => void] {
    const [value, setValue] = useState<T | undefined>(() => {
        const stored = localStorage.getItem(storageKey);
        return stored ? JSON.parse(stored) : fallbackState;
    });

    useEffect(() => {
        if (value) {
            localStorage.setItem(storageKey, JSON.stringify(value));
        } else {
            localStorage.removeItem(storageKey);
        }
    }, [value, storageKey]);


    return [value, setValue]
}
