import {useParams} from "react-router-dom";
import AppPage from "../AppPage/AppPage";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Individual} from "../../model/Individual";
import {AppServiceContext} from "../../providers/AppServiceProvider/AppServiceProvider";
import IndividualSummary from "../../components/IndividualSummary/IndividualSummary";
import ParentsSection from "./ParentsSection/ParentsSection";
import SiblingsSection from "./SiblingsSection/SiblingsSection";
import SpousesSection from "./SpousesSection/SpousesSection";
import ChildrenSection from "./ChildrenSection/ChildrenSection";
import AppPagePlaceholder from "../AppPage/AppPagePlaceholder";
import IndividualSummaryPlaceholder from "../../components/IndividualSummary/IndividualSummaryPlaceholder";
import {Button, Overlay, Popover, PopoverBody} from "react-bootstrap";
import {AppUserContext} from "../../providers/AppUserProvider/AppUserProvider";
import IndividualDistanceAlert from "./IndividualDistanceAlert/IndividualDistanceAlert";


function IndividualPage() {
    const params = useParams<{ id: string }>();
    const {individualService} = useContext(AppServiceContext);
    const [individual, setIndividual] = useState<Individual | undefined>();

    useEffect(() => {
        setIndividual(individualService.findOne(params.id || '00000'))
    }, [individualService, params]);


    if (individual) {
        return (
            <AppPage id={"individual-page"} title={<Title individual={individual}></Title>}>

                <IndividualDistanceAlert individual={individual}></IndividualDistanceAlert>

                <IndividualSummary individual={individual}></IndividualSummary>

                <ParentsSection individual={individual}></ParentsSection>

                <SiblingsSection individual={individual}></SiblingsSection>

                <SpousesSection individual={individual}></SpousesSection>

                <ChildrenSection individual={individual}></ChildrenSection>

            </AppPage>
        );
    } else {
        return (<AppPagePlaceholder>
            <IndividualSummaryPlaceholder></IndividualSummaryPlaceholder>
            <ParentsSection></ParentsSection>
            <SiblingsSection></SiblingsSection>
            <SpousesSection></SpousesSection>
            <ChildrenSection></ChildrenSection>

        </AppPagePlaceholder>)
    }
}

function Title({individual}: { individual: Individual }) {
    const target = useRef(null);
    const {user: currentUser, setUser: setCurrentUser} = useContext(AppUserContext);

    const itsMe = currentUser?.id === individual.id;

    return (
        <div className="d-flex">
            <span className="me-auto">{individual.name}</span>
            <Overlay target={target.current} placement="left-start" show={!currentUser}>
                <Popover style={{zIndex: 1029}}>
                    <PopoverBody>
                        Clique sur ce bouton pour indiquer que tu es {individual.name}.
                    </PopoverBody>
                </Popover>
            </Overlay>

            {
                itsMe ?
                    <Button ref={target} variant="outline-secondary" onClick={() => setCurrentUser(undefined)}>
                        Je ne suis pas {individual.name}
                    </Button> :
                    <Button ref={target} variant="outline-success" onClick={() => setCurrentUser(individual)}>
                        Je suis {individual.name}
                    </Button>
            }
        </div>
    );
}

export default IndividualPage;
