import {Individual} from "../../../model/Individual";
import React, {useContext, useEffect, useState} from "react";
import {AppServiceContext} from "../../../providers/AppServiceProvider/AppServiceProvider";
import IndividualCard from "../../../components/IndividualCard/IndividualCard";
import IndividualCardPlaceholder from "../../../components/IndividualCard/IndividualCardPlaceholder";
import {AppUserContext} from "../../../providers/AppUserProvider/AppUserProvider";
import useIndividualRelationsService from "../../../hooks/useIndividualRelationsService";

export default function WeddingPart(props: { fernandSamson?: Individual }) {

    const {individualService, familyService} = useContext(AppServiceContext);
    const {user: currentUser} = useContext(AppUserContext);

    const individualRelationsService = useIndividualRelationsService(currentUser?.id);

    const [augusteSamson, setAugusteSamson] = useState<Individual | undefined>();
    const [adelineBretagne, setAdelineBretagne] = useState<Individual | undefined>();
    const [eugenieRobin, setEugenieRobin] = useState<Individual | undefined>();

    useEffect(() => {
        if (props.fernandSamson?.familyChild) {
            const augusteSamson = individualService.findOne(
                familyService.findOne(props.fernandSamson.familyChild)?.husband || ''
            )
            setAugusteSamson(augusteSamson);

        }
    }, [props.fernandSamson, familyService, individualService]);

    useEffect(() => {
        if (props.fernandSamson?.familyChild) {
            const adelineBretagne = individualService.findOne(
                familyService.findOne(props.fernandSamson.familyChild)?.wife || ''
            )
            setAdelineBretagne(adelineBretagne);

        }
    }, [props.fernandSamson, familyService, individualService]);

    useEffect(() => {
        if (props.fernandSamson?.familySpouses) {
            const eugenieRobin = props.fernandSamson.familySpouses
                .map(familySpouse => familyService.findOne(familySpouse))
                .map(familySpouse => individualService.findOne(familySpouse?.wife || ''))
                .pop();

            setEugenieRobin(eugenieRobin);

        }
    }, [props.fernandSamson, familyService, individualService]);

    return <section>
        <p>
            Le 31 juillet 1861, en mairie d&apos;AMBOISE
        </p>

        <div className="d-flex flex-column flex-lg-row justify-content-evenly align-items-center mb-3">
            {
                augusteSamson ?
                    <IndividualCard individual={augusteSamson} distance={individualRelationsService.distances[augusteSamson.id]} options={{
                        civilityTitle: true,
                        infos: ['occupations', 'birth'],
                        extras: [
                            'fils de Joseph SAMSON, cordier',
                            'et de Arsène PAVY son épouse'
                        ]
                    }}></IndividualCard> :
                    <IndividualCardPlaceholder infoPlaceholders={5}></IndividualCardPlaceholder>

            }

            <div className="text-center">
                épousa
            </div>

            {
                adelineBretagne ?
                    <IndividualCard individual={adelineBretagne} distance={individualRelationsService.distances[adelineBretagne.id]} options={{
                        civilityTitle: true,
                        infos: ['occupations', 'birth'],
                        extras: [
                            'fille de Pierre Mathurin BRETAGNE, receveur buraliste de l\'octroi',
                            'et de Sophie Elisabeth RABIER son épouse'
                        ]
                    }}></IndividualCard> :
                    <IndividualCardPlaceholder infoPlaceholders={5}></IndividualCardPlaceholder>
            }

        </div>

        <p>
            De ce mariage nacquit
        </p>

        <div className="d-flex flex-column flex-lg-row justify-content-evenly mb-3">
            {
                props.fernandSamson ?
                    <IndividualCard individual={props.fernandSamson} distance={individualRelationsService.distances[props.fernandSamson.id]} options={{
                        civilityTitle: true,
                        infos: ['birth']
                    }}></IndividualCard> :
                    <IndividualCardPlaceholder infoPlaceholders={1}></IndividualCardPlaceholder>
            }
        </div>

        <p>
            Il semble qu&apos;il soit venu à Paris, assez jeune, afin d&apos;apprendre son métier de graveur sur bois.
            Il reçut le 3 mars 1878 un &quot;grand prix d&apos;apprentissage&quot;; délivré &quot;Au nom de la Société
            charitable Fondatrice
            des école gratuites&quot;.
            Il demeurait alors 14 rue des Vents, vraisemblablement avec sa mère, établie antiquaire.
        </p>
        <p>
            Il avait conservé de solides attaches à AMBOISE et s&apos;y maria le 8 août 1893 avec
        </p>

        <div className="d-flex flex-column flex-lg-row justify-content-evenly mb-3">
            {
                eugenieRobin ?
                    <IndividualCard individual={eugenieRobin} distance={individualRelationsService.distances[eugenieRobin.id]} options={{
                        civilityTitle: true,
                        infos: ['occupations', 'birth'],
                        extras: [
                            'fille de Michel ROBIN, débitant',
                            'et de Louise Françoise JOLY',
                        ]
                    }}></IndividualCard> :
                    <IndividualCardPlaceholder></IndividualCardPlaceholder>
            }
        </div>

    </section>;
};
