import {Alert} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import React, {useContext} from "react";
import {AppUserContext} from "../../../providers/AppUserProvider/AppUserProvider";
import useIndividualRelationsService from "../../../hooks/useIndividualRelationsService";
import {Individual} from "../../../model/Individual";
import {AppServiceContext} from "../../../providers/AppServiceProvider/AppServiceProvider";

export default function IndividualDistanceAlert({individual}: {individual: Individual}) {
    const {user: currentUser} = useContext(AppUserContext);
    const {individualService} = useContext(AppServiceContext);

    const individualRelationsService = useIndividualRelationsService(currentUser?.id);

    if (currentUser && currentUser.id !== individual.id) {
        return <Alert variant="light">
            <Alert.Heading>

                <FormattedMessage id="IndividualPage.distance" values={{
                    distance: individualRelationsService.distances[individual.id],
                    name: individual.name
                }}></FormattedMessage>
            </Alert.Heading>

            <ol>
                {
                    individualRelationsService.findPathTo(individual.id).map((r, idx, rArray) => {
                        const i1 = individualService.findOne(r.i1);
                        const i2 = individualService.findOne(r.i2);

                        if (i1 && i2) {

                            if (idx === 0) {
                                return <li key={idx}>

                                    <FormattedMessage id="IndividualDistanceAlert.relation#first" values={{
                                        i1Sex: i1.sex,
                                        relation: r.relation,
                                        individualLink: () => {
                                            if (idx === rArray.length - 1) {
                                                return <strong>{i2.name}</strong>;
                                            } else {
                                                return <Alert.Link as={Link} to={`/individus/${i2.id}`}>{i2.name}</Alert.Link>;
                                            }
                                        }
                                    }}></FormattedMessage>

                                </li>;
                            } else {
                                return <li key={idx}>

                                  <FormattedMessage id="IndividualDistanceAlert.relation" values={{
                                      i1Sex: i1.sex,
                                      relation: r.relation,
                                      individualLink: () => {
                                          if (idx === rArray.length - 1) {
                                              return <strong>{i2.name}</strong>;
                                          } else {
                                              return <Alert.Link as={Link} to={`/individus/${i2.id}`}>{i2.name}</Alert.Link>;
                                          }
                                      }
                                  }}></FormattedMessage>
                                </li>;
                            }

                        } else {
                            return null;
                        }

                    })
                }
            </ol>

        </Alert>;
    } else {
        return null;
    }

}
